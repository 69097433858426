import styled from 'styled-components';

import { contentWidth } from '../theme';
import media from '../media';

const StyledHomePage = styled.div`
  background: ${({ theme }) => theme.light};

  ${({ theme }) =>
    theme.grid &&
    media.break`
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "hheader hheader"
      "callout callout"
      "body resources"
      "video video";
    grid-column-gap: 2rem;
    justify-content: center;
      .home-header {
        grid-area: hheader;
      }
      .home-callout {
        grid-area: callout;
      }
      .home-body {
        grid-area: body;
        width: initial;
        margin-left: 2rem;
        margin-right: 0;
        max-width: 800px;
        justify-self: end;
        .content-wrapper {
          width: 100%;
        }
      }
      .resources-list {
        grid-area: resources;
        width: initial;
        margin-right: 2rem;
        margin-left: 0;
        max-width: 500px;
      }
      .home-video {
        grid-area: video;
      }
  `}
`;

const Callout = styled.div`
  background: ${({ theme }) => theme.blue};
  color: white;
  text-align: center;
  padding: 2rem;
  p {
    ${contentWidth}
    width: 500px;
    margin-bottom: 0;
    line-height: 1.6;
    font-weight: 700;
    ${media.break`
      font-size: 1.8rem;
    `}
  }
`;

const HomeBody = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.shadow};
  ${contentWidth};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem 2rem;
  line-height: 1.4;
  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.darkOrange};
    font-weight: 300;
    font-family: ${({ theme }) => theme.font.family};
    letter-spacing: 0;
  }
`;

export { StyledHomePage, Callout, HomeBody };