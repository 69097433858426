import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

import media from '../media';

const HomeHeader = ({ logo, image }) => {
  return (
    <StyledHomeHeader className="home-header">
      <div className="home-header__logo-container">
        <Image className="home-header__logo" fluid={logo.asset.fluid} />
      </div>
      <div className="home-header__image-container">
        <Image className="home-header__image" fluid={image.asset.fluid} />
      </div>
    </StyledHomeHeader>
  );
};

const StyledHomeHeader = styled.header`
  display: flex;
  flex-direction: column;
  background: white;

  ${media.break`
    flex-direction: row;
    align-items: center;
    height: 300px;
    overflow: hidden;
  `}

  .home-header {
    &__logo-container {
      padding: 2rem;
      ${media.break`
        flex: 0 1 500px;
        padding: 5rem;
      `}
    }

    &__image-container {
      ${media.break`
        flex: 1 1 55%;
      `}
    }

    &__logo {}
  }

`;

export default HomeHeader;