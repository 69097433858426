import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Global/Wrapper';
import Home from '../components/Home';

const HomePage = ({ data }) => {
  const { sanityHomePage } = data;

  return (
    <Wrapper
      home
      title={sanityHomePage.title}
      seoSettings={sanityHomePage.seoSettings}
    >
      <Home {...sanityHomePage} />
    </Wrapper>
  );
};

export const query = graphql`
  {
    sanityHomePage {
      id
      title
      calloutText
      _rawBody(resolveReferences: { maxDepth: 10 })
      homeLogo {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      homeImage {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      homeResources {
        resourceRef {
          id
          title
          resourceType
          _rawBody
        }
      }
      seoSettings {
        metaDescription
        pageTitle
      }
    }
  }
`;

export default HomePage;
