import React, { useContext } from 'react';
import styled from 'styled-components';

import SiteContext from '../SiteContext';

const HomeVideo = () => {
  const { viewport } = useContext(SiteContext);

  return (
    <StyledHomeVideo className="home-video" viewport={viewport}>
      <div className="fluid-video">
        {/* <iframe
          title="Home Video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/2gMGV2vTUNU"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe> */}
        <iframe
          width="640"
          height="360"
          src="https://player.vimeo.com/video/386351645"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
    </StyledHomeVideo>
  );
};

const StyledHomeVideo = styled.div`
  background: ${({ theme }) => theme.darkTeal};
  padding: 1rem;
  .fluid-video {
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.sizes.break}px) {
    .fluid-video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      width: ${({ viewport }) => viewport.width - 20}px;
      max-width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default HomeVideo;
