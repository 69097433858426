import React from 'react';

import { StyledHomePage, Callout, HomeBody } from './HomeStyles';
import HomeHeader from './HomeHeader';
import Content from '../Content';
import Resources from '../Resources';
import HomeVideo from './HomeVideo';

const Home = ({
  homeLogo,
  homeImage,
  calloutText,
  _rawBody,
  homeResources,
}) => {
  const resources = homeResources.map(
    ({ resourceRef: { id, title, resourceType, _rawBody } }) => {
      return {
        node: {
          id,
          title,
          resourceType,
          _rawBody,
        },
      };
    }
  );

  return (
    <StyledHomePage>
      <HomeHeader logo={homeLogo} image={homeImage} />
      <Callout className="home-callout">
        <p className="home-callout__text">{calloutText}</p>
      </Callout>
      <HomeBody className="home-body">
        <Content>{_rawBody}</Content>
      </HomeBody>
      <Resources resources={resources} home />
      <HomeVideo />
    </StyledHomePage>
  );
};

export default Home;
